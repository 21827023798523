import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Rumble } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  title?: string
  variant?: Variant
}

export const Intro = memo(function Intro({
  cta,
  description,
  title,
  variant = 'default',
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container variant={variant}>
      <Rumble />
      <Rumble />
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
      {cta ? (
        <FadeInUp>
          <CTA {...cta} variant="simple" />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  max-width: 48.75rem;
  margin: auto;
  padding: 14.75rem 2rem;
  position: relative;
  text-align: center;

  svg {
    position: absolute;
    z-index: -1;
    &:first-of-type {
      width: auto;
      height: 10.9375rem;
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight3};
      stroke-width: 0.3;
      top: 8.75rem;
      right: 0;
    }
    &:last-of-type {
      width: auto;
      height: 21.875rem;
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight3};
      stroke-width: 0.15;
      top: 15.625rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  ${({ variant }) => {
    switch (variant) {
      case 'filters':
        return css`
          margin-top: 6.25rem;

          @media (max-width: 1023px) {
            margin-top: 0;
          }
        `
    }
  }}

  @media (max-width: 1023px) {
    max-width: none;
    padding-top: 8.3125rem;
    padding-bottom: 7rem;

    svg {
      &:first-of-type {
        height: 6.375rem;
        top: 6.25rem;
        right: 2.125rem;
      }
      &:last-of-type {
        height: 12.75rem;
        top: 9.6875rem;
      }
    }
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.75rem;
  margin-bottom: 1.875rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'filters'
